import React, { useCallback, useEffect, useRef, useState } from "react";
import "./ProductItem.css";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { addProductToCart, deleteProductFromCart } from "../../store/cart/cart";
import Preloader from "../Preloader/Preloader";
import { useSpring, animated } from "react-spring";

const ProductItem = ({
  // brand,
  count,
  name,
  opt,
  price,
  barcode,
  // uid,
  sku,
  availableQty,
  handleChangeOpt,
}) => {
  const imageRef = useRef(null);
  const [imgLoaded, setImgLoaded] = useState(false);
  const cart = useSelector((state) => state.cart.shoppingCart);
  const dispatch = useDispatch();
  const products = useSelector((state) => state.catalogue.catalogueProducts);
  let imgLoadCounter = 0;
  // const flyContainer = useRef();

  // const handleFly = (e) => {
  //   const rect = e.target.getBoundingClientRect();
  //   flyContainer.current.style.display = "none";
  //   console.log(flyContainer.current.style);
  // };

  return (
    <>
      {/* <div ref={flyContainer}>
        <h2>Hello</h2>
      </div> */}
      <div className="productItemWrapper">
        <div className="productImageWrapper">
          {!imgLoaded && <Preloader />}
          <img
            ref={imageRef}
            className="productImage"
            // src={"https://zoougolok.com.ua/upload/iblock/" + barcode + ".jpg"}
            src={
              "https://detta.com.ua/upload/products/" +
              barcode +
              ".jpg" +
              "?t=" +
              new Date().getTime()
            }
            onError={(e) => {
              // if (imgLoadCounter === 0) {
              //   imgLoadCounter += 1;
              //   e.target.src =
              //     "https://detta.com.ua/upload/products/" + barcode + ".png";
              // }
              // else {

              e.target.src =
                "https://res.cloudinary.com/demubqxmn/image/upload/v1679907783/detta/detta_u2wjwc.png" +
                "?t=" +
                new Date().getTime();
              // }
            }}
            onLoad={() => {
              setImgLoaded(true);
            }}
          />
        </div>

        <div
          style={{
            padding: "1px",
            overflowWrap: "break-word",
          }}
        >
          <h2>{name}</h2>
        </div>
        <p className={count <= 0 ? "itemPriceDisabled" : "itemPrice"}>
          {price} ₴
        </p>
        <div className="propsWrapper">
          {opt.map((el) => {
            const inCart = cart.find((item) => item.barcode === el.barcode);
            const inStock = products.find(
              (product) => product.barcode === el.barcode
            );
            return (
              <div className="productProps">
                <div className="productOptWrapper">
                  <p
                    className={el.barcode === barcode ? "active" : "productOpt"}
                    data-id={el.barcode}
                    onClick={(e) => {
                      handleChangeOpt(e.target.dataset.id, imageRef, sku);
                    }}
                  >
                    {el.opt}
                  </p>
                </div>

                {inStock.count === 0 && (
                  <Button
                    className="addToCartBtn"
                    disabled
                    style={{ color: "white", backgroundColor: "darkgrey" }}
                  >
                    Відсутній
                  </Button>
                )}
                {inStock.count !== 0 && !inCart && (
                  <Button
                    className="addToCartBtn"
                    data-id={el.barcode}
                    onClick={(e) => {
                      const findProduct = products.find(
                        (item) => item.barcode === el.barcode
                      );
                      dispatch(addProductToCart(findProduct));
                      handleChangeOpt(e.target.dataset.id, imageRef, sku);
                      handleFly(e);
                    }}
                  >
                    Купити
                  </Button>
                )}
                {inStock.count !== 0 && inCart && (
                  <div className="incAndDecBtnsWrapper">
                    <Button
                      className="incrementAndDecrementButtons"
                      data-id={el.barcode}
                      onClick={(e) => {
                        const findProduct = products.find(
                          (item) => item.barcode === el.barcode
                        );
                        dispatch(deleteProductFromCart(findProduct));
                        handleChangeOpt(e.target.dataset.id, imageRef, sku);
                      }}
                    >
                      -
                    </Button>
                    <p className="ordererQty">{inCart.orderedQty}</p>
                    <Button
                      className="incrementAndDecrementButtons"
                      data-id={el.barcode}
                      style={{
                        color: inStock.count === inCart.orderedQty && "white",
                        backgroundColor:
                          inStock.count === inCart.orderedQty && "darkgrey",
                      }}
                      disabled={inStock.count === inCart.orderedQty && true}
                      onClick={(e) => {
                        const findProduct = products.find(
                          (item) => item.barcode === el.barcode
                        );
                        dispatch(addProductToCart(findProduct));
                        handleChangeOpt(e.target.dataset.id, imageRef, sku);
                      }}
                    >
                      +
                    </Button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ProductItem;
