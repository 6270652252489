import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cartHasUnmanagedProducts,
  decrementCartProduct,
  deleteAllProducts,
  incrementCartProduct,
  setCheckoutForm,
} from "../../store/cart/cart";
import {
  getCatalogueCategories,
  setIsLoading,
} from "../../store/catalogue/catalogueSlice";
import Button from "../Button/Button";
import CheckoutForm from "../CheckoutForm/CheckoutForm";
import "./Cart.css";
import DeleteFromCartIMG from "./images/deleteFromCartIMG";

const Cart = () => {
  const productInCart = useSelector((state) => state.cart.shoppingCart);
  const categories = useSelector(
    (state) => state.catalogue.catalogueCategories
  );

  const isCheckoutFormOpen = useSelector(
    (state) => state.cart.isCheckoutFormOpen
  );

  const calculateSum = () => {
    const sum = productInCart.reduce(
      (acc, el) => acc + el.price * el.orderedQty,
      0
    );
    return parseFloat(sum).toFixed(2);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(cartHasUnmanagedProducts());
      dispatch(setIsLoading(true));
      dispatch(getCatalogueCategories());
    }
  }, []);

  useEffect(() => {
    dispatch(cartHasUnmanagedProducts());
  }, []);
  return (
    <>
      {productInCart.length === 0 ? (
        <h2 style={{ textAlign: "center", paddingTop: "20%" }}>
          У вашому кошику нічого немає
        </h2>
      ) : (
        <>
          {productInCart.map(
            ({ name, opt, price, orderedQty, barcode, count }) => {
              return (
                <div>
                  <div className="productCartWrapper">
                    <div className="cartImageWrapper">
                      <img
                        className="cartProductImage"
                        // src={
                        //   "https://zoougolok.com.ua/upload/iblock/" +
                        //   barcode +
                        //   ".jpg"
                        // }
                        src={
                          "https://detta.com.ua/upload/products/" +
                          barcode +
                          ".jpg" +
                          "?t=" +
                          new Date().getTime()
                        }
                        onError={(e) => {
                          // e.target.src =
                          //   "https://zoougolok.com.ua/nopic/default.png";
                          e.target.src =
                            "https://res.cloudinary.com/demubqxmn/image/upload/v1679907783/detta/detta_u2wjwc.png" +
                            "?t=" +
                            new Date().getTime();
                        }}
                      />
                    </div>

                    <div className="cartLineProduct">
                      <div className="cartLineWrapper">
                        <div className="cartProductDataWrapper">
                          <h2 className="cartProducName">{name}</h2>
                          <p className="cartProductOpt">{opt}</p>
                        </div>
                        <div
                          className="productCartDeleteBtn"
                          onClick={() => {
                            dispatch(deleteAllProducts(barcode));
                          }}
                        >
                          <DeleteFromCartIMG />
                        </div>
                      </div>

                      <div className="cartProductQty">
                        <p className="cartOrderedAndFinalQty">
                          {parseFloat(price).toFixed(0)} ₴
                        </p>
                        <div className="cartBtnWrapper">
                          <Button
                            style={{
                              color: orderedQty === 1 && "white",
                              backgroundColor: orderedQty === 1 && "darkgrey",
                            }}
                            disabled={orderedQty > 1 ? false : true}
                            className="incrementAndDecrementButtons"
                            onClick={() => {
                              dispatch(decrementCartProduct(barcode));
                            }}
                          >
                            -
                          </Button>
                          <p>{orderedQty}</p>
                          <Button
                            className="incrementAndDecrementButtons"
                            style={{
                              color: count === orderedQty && "white",
                              backgroundColor:
                                count === orderedQty && "darkgrey",
                            }}
                            disabled={count === orderedQty && true}
                            onClick={() => {
                              dispatch(incrementCartProduct(barcode));
                            }}
                          >
                            +
                          </Button>
                        </div>

                        <p className="cartOrderedAndFinalQty">
                          {parseFloat(price * orderedQty).toFixed(0)} ₴
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
          <p style={{ textAlign: "center" }}>
            Сумма замовлення:{" "}
            <span style={{ fontWeight: "bold" }}>{calculateSum()} ₴</span>
          </p>

          <div className="orderBtnWrapper">
            <Button
              className="orderBtn"
              onClick={() => {
                scrollTo(0, 0);
                dispatch(setCheckoutForm(true));
              }}
            >
              Замовити
            </Button>
          </div>
        </>
      )}

      {isCheckoutFormOpen
        ? document.body.classList.add("toggleScroll")
        : document.body.classList.remove("toggleScroll")}
      {isCheckoutFormOpen && <CheckoutForm />}
    </>
  );
};

export default Cart;
