import * as Yup from "yup";

const validationShema = Yup.object().shape({
  fullName: Yup.string().required("Name is required"),
  nurseryName: Yup.string().required("Nursery Name is required"),
  paymentMethod: Yup.string().required("Payment method is required"),
  isDeliveryInCity: Yup.string().required("Payment method is required"),
  deliveryAddress: Yup.string().required("Delivery address is required"),
  telephone: Yup.string()
    .min(9)
    .max(12)
    .required("Telephone number is required"),
});

export default validationShema;
