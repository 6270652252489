import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useTelegram from "../../hooks/useTelegram";

import Button from "../Button/Button";
import "./CompletedOrder.css";

const CompletedOrder = () => {
  const { onClose } = useTelegram();
  const { orderNO } = useParams();
  const navigate = useNavigate();
  return (
    <div className="completedOrderWrapper">
      <h2>Дякуємо за замовлення</h2>
      <p>Ваше замовлення номер:</p>
      <p className="completedOrderNumber">{orderNO}</p>
      <p>було прийнято</p>
      <p>Наш менеджер зв'яжеться з Вами найближчим часов</p>
      <div className="completedOrderBtnWrapper">
        <Button
          onClick={() => {
            navigate("/", { replace: true });
          }}
          className="completedOrderBtnHome"
        >
          Продовжити покупки
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
          className="completedOrderBtnHome"
        >
          Закрити
        </Button>
      </div>
    </div>
  );
};

export default CompletedOrder;
